<template>
  <div style="height: 100%">
    <nesi-user-common>
      <template #content>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 450px;
            flex-direction: column;
          "
        >
          <qrcode-vue :value="qrCodeUrl" :size="220" level="H" />
          <template v-if="show">
            <div style="padding: 10px 0; font-size: 14px; color: red">
              二维码已失效，请重新点击获取
            </div>
            <el-button type="primary" style="margin-top: 8px" @click="getUrl">
              重新获取二维码
            </el-button>
          </template>
          <div v-else style="padding: 10px 0; font-size: 14px">
            手机微信扫一扫
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { defineComponent, ref, computed, onMounted } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { useStore } from 'vuex'
import { getUserInfoApi } from '@/api/user'
import { useRouter } from 'vue-router'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
defineComponent({
  NesiUserCommon
})
const qrCodeUrl = ref('')
const store = useStore()
const router = useRouter()

// token
const token = computed(() => store.getters.token)
const url =
  process.env.VUE_APP_DOMAIN_APP +
  '/subPages/mine/appointment/pcSign?token=' +
  token.value +
  '&bussType=2'
qrCodeUrl.value = url

// 链接有效开始计时
const linkTime = ref({})
const userDefaultTime = 420000 // 7分钟失效
const lintTimeout = ref(120000) // 两分钟后失效 120000
const getUserTimeout = ref(userDefaultTime) // 计时器
const userTime = ref(10000) // 没10秒钟执行一次获取用户信息
const show = ref(false)
const timeInterval = ref({})

onMounted(() => {
  handleUser()
  handleLink()
})

const getUrl = () => {
  show.value = false
  qrCodeUrl.value = url

  // 重置获取用户信息，销毁该定时器
  getUserTimeout.value = userDefaultTime
  clearInterval(timeInterval.value)

  handleLink()
  handleUser()

  // 重新启动获取用户信息接口
}

// 定时二维码失效
const handleLink = () => {
  linkTime.value = setTimeout(() => {
    qrCodeUrl.value = ''
    show.value = true
    clearTimeout(linkTime.value)
  }, lintTimeout.value)
}

// 获取用户信息
const handleUser = () => {
  timeInterval.value = setInterval(async () => {
    if (getUserTimeout.value === 0 || getUserTimeout.value < 0) {
      clearInterval(timeInterval.value)
      return
    }
    getUserTimeout.value = getUserTimeout.value - userTime.value
    const res = await getUserInfoApi()
    if (res.userInfo && res.userInfo.faceAuth === 1) {
      store.commit('user/setUserInfo', res.userInfo)
      clearInterval(timeInterval.value)
      router.push('/user/house/index')
    }
  }, userTime.value)
}
</script>
<style lang="scss" scoped></style>
